import { ref, watch, computed, onMounted } from "@vue/composition-api";
import i18n from "@/libs/i18n";
import dayjs from "dayjs";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { evaluationService } from "@/services/evaluation";
import { resolveEvaluationStatus, resolveEvaluationText } from "@/libs/helper";
export default function useCompany() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "company", sortable: true, label: i18n.t("company") },
    { key: "company.email", sortable: true, label: i18n.t("email") },
    { key: "status", sortable: true, label: "สถานะอัพโหลดเอกสาร" },
    { key: "actions", label: i18n.t("actions") },
  ];

  const selectMonth = ref(dayjs().format("YYYY-MM"));
  const selectFilter = ref("overall");
  const monthOptions = ref([]);
  const allCompany = ref([]);
  const statics = ref(null);

  const buildDefaultParams = () => {
    const filter = {
      page: currentPage.value,
      size: perPage.value,
      currentDate: selectMonth.value,
      searchQuery: searchQuery.value,
      // type: selectFilter.value,
    };

    return new URLSearchParams(filter).toString();
  };

  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const onClickSearch = () => {
    getCompany();
  };

  onMounted(() => {
    getMonth();
    getCompany();
    getStaticAllCompany();
  });

  const getStaticAllCompany = async () => {
    try {
      const filter = {
        currentDate: selectMonth.value,
      };

      const queryParams = new URLSearchParams(filter).toString();

      const result = await evaluationService.getStaticAllCompany(queryParams);

      if (result.status) {
        statics.value = result.data;
      }
    } catch (e) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Can't get static",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const getMonth = async () => {
    try {
      const result = await evaluationService.getMonth();

      if (result.status) {
        monthOptions.value = result.data;
      }
    } catch (e) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Can't get static",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const getCompany = () => {
    const params = buildDefaultParams();
    evaluationService
      .getCompanyByMonth(params)
      .then((response) => {
        const { data, pagination, status } = response;

        if (status) {
          allCompany.value = data;
          totalUsers.value = pagination.totalItems;
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching comanpy list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    allCompany,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,

    monthOptions,
    selectMonth,
    statics,
    resolveEvaluationStatus,
    resolveEvaluationText,
    onClickSearch,
  };
}
