<template>
  <div>
    <b-row class="match-height" v-if="statics">
      <b-col xl="3" md="3" sm="6">
        <statistic-card-vertical
          icon="UserIcon"
          :statistic="statics.allEmployee"
          statistic-title="พนักงานทั้งหมด"
          color="info"
        />
      </b-col>
      <b-col xl="3" md="3" sm="6">
        <statistic-card-vertical
          color="primary"
          icon="UserIcon"
          :statistic="statics.successEvaluation"
          statistic-title="ประเมินแล้ว"
        />
      </b-col>
      <b-col xl="3" md="3" sm="6">
        <statistic-card-vertical
          color="success"
          icon="UserIcon"
          :statistic="statics.waitForEvaluation"
          statistic-title="ยังไม่ประเมิน"
        />
      </b-col>
      <b-col xl="3" md="3" sm="6">
        <statistic-card-vertical
          hide-chart
          color="danger"
          icon="UserIcon"
          :statistic="statics.pendingEvaluation"
          statistic-title="รออนุมัติ"
        />
      </b-col>
    </b-row>

    <b-row class="justify-content-sm-between">
      <b-col xl="9" md="9" sm="6">
        <b-tabs pills nav-class="nav-pill-primary">
          <b-tab active>
            <template slot="title">
              {{ $t("overall") }}
              <!-- <b-badge pill variant="danger" style="margin-left: 5px">
                10
              </b-badge> -->
            </template>
          </b-tab>
          <!-- <b-tab>
            <template slot="title">
              {{ $t("pending_evaluation") }}
              <b-badge pill variant="danger" style="margin-left: 5px"
                >4</b-badge
              >
            </template>
          </b-tab>
          <b-tab>
            <template slot="title">
              {{ $t("success_evaluation") }}
              <b-badge pill variant="danger" style="margin-left: 5px">
                6
              </b-badge>
            </template>
          </b-tab> -->
        </b-tabs>
      </b-col>

      <b-col xl="2" md="2" sm="6" class="text-right">
        <!-- <b-card> </b-card> -->
        <v-select
          v-model="selectMonth"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="monthOptions"
          :reduce="(val) => val.value"
          :clearable="false"
          class="bg-white"
        />
      </b-col>
    </b-row>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group class="mr-1" style="width: 70%">
                <b-form-input
                  placeholder="ค้นหาชื่อบริษัท เบอร์โทรศัพท์ ..."
                  v-model="searchQuery"
                  trim
                />
                <b-input-group-append>
                  <b-button variant="outline-primary" @click="onClickSearch()">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="allCompany"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(company)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.avatar"
                :text="avatarText(data.fullName)"
                :variant="`light-${resolveEvaluationStatus(data.item.logo)}`"
              />
            </template>
            <div class="font-weight-bolder">
              {{ data.item.company.name }}
            </div>
            <small class="text-muted">@{{ data.item.company.mobileNo }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveEvaluationStatus(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <span
            style="margin-left: 50px"
            class="bullet bullet-sm"
            :class="`bullet-${data.item.commercialFile ? 'success' : 'danger'}`"
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link
            :to="{
              name: 'admin-evaluation-approve',
              query: { company: data.item.company._id },
            }"
          >
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">{{ $t("employees.view") }}</span>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTab,
  BTabs,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useCompanyEvaluation from "./useCompanyEvaluation";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    StatisticCardVertical,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTab,
    BTabs,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false);

    const {
      allCompany,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      selectMonth,
      monthOptions,

      statics,
      resolveEvaluationStatus,
      resolveEvaluationText,
      selectFilter,
      onClickSearch,
    } = useCompanyEvaluation();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      allCompany,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,
      selectMonth,
      monthOptions,

      statics,
      resolveEvaluationStatus,
      resolveEvaluationText,
      selectFilter,
      onClickSearch,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
